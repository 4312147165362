<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                    <v-layout align-center pr-4>
                    <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                        <div>
                        <strong>{{ snackbar.title }}</strong>
                        </div>
                        <div>{{ snackbar.text }}</div>
                    </v-layout>
                    </v-layout>
                    <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="pb-3" outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <span class="blue-lcd mb-2 font-12">Start Period </span>
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_from"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <span class="blue-lcd mb-2 font-12">End Period </span>
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        outlined
                                        dense
                                        clearable
                                        v-model="date_to"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 mt-2 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6 mt-5" sm="6" md="2">
                                <v-btn class="border-12"  style="padding: 20px;"
                                color="info" elevation="2" small @click="submit()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="isVisible === true" class="mt-5">
            <v-col class="col-12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-data-table
                                    :headers="header"
                                    :items="data"
                                    :loading="$store.state.overlay"
                                    :item-class="tr_datatable"
                                    hide-default-footer
                                    fixed-header
                                    height="400"
                                    :divider="true"
                                    :light="true"
                                    disable-pagination
                                    disable-sort
                                >        
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {viewQueryProduction} from "../../../backend-api/sr/production/view_query_production"
import {peta} from "../../../backend-api/util/peta"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Query Production',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false, 
            isVisible:false,
            header:[],
            data:[],
        }
    },
    mounted() {
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            const rowClass = 'tr_datatable'
            return rowClass;
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)
            // const start = this.date_from ? this.date_from.replace('-','') : ''
            // const end = this.date_to ? this.date_to.replace('-','') : ''
            const start = this.date_from
            const end = this.date_to
            const reqBody = {
                "periode_start": start,
                "periode_end": end,
                "mach": [],
                "quality_id": [],
            }
            const respData = await viewQueryProduction.fetchIndex(``, reqBody, false, false, false)
            if (respData.status === 200) {
                const transposedData = this.mapLevelOne(respData.data.data)

                let transposed = transposedData.sort((a, b) => {
                    if (a.periode !== b.periode) {
                        return a.periode - b.periode;
                    } else {
                        return a.quality.localeCompare(b.quality);
                    }
                });

                const index = transposed.findIndex(obj => obj.quality === 'Total');
                let removedObj = {};

                if (index !== -1) {
                    removedObj = transposed.splice(index, 1)[0];
                }
                transposed.push(removedObj)

                this.data = transposed

                console.log(this.data)
                const hdrKeyDesc = {
                    // 'periode': 'Periode',
                    'quality': 'Quality',
                    'GLV1': 'Prod CZL 01',
                    'GLV2': 'Prod CZL 02',
                    'SLT1': 'Prod SL 01',
                    'SLT2': 'Prod SL 02',
                    'DELV': 'Delivery',
                    'GLV': 'End Zinium',
                    'SLT': 'End Slitting'
                };
                this.header = peta.mapLevelTwoV2(hdrKeyDesc, transposed)
                this.header = this.header.filter(obj => obj.text !== "periode");

                console.log(this.header)
                this.$store.dispatch('setOverlay', false)
                this.isVisible = true
            } else {
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)
                return false
            }
        },
        mapLevelOne(data){
            const transposedData = [];

            // Group data by periode and quality_id
            const groupedData = data.reduce((acc, obj) => {
                const { periode, quality_id, mach, wgt } = obj;
                if (!acc[periode]) acc[periode] = {};
                if (!acc[periode][quality_id]) acc[periode][quality_id] = {};
                acc[periode][quality_id][mach] = this.$store.getters.convertToCurrencyUs(parseFloat(wgt));
                return acc;
            }, {});

            // Define machines
            const machines = ['GLV1', 'GLV2', 'SLT1', 'SLT2', 'DELV', 'GLV', 'SLT'];

            // Iterate through grouped data to create transposed format
            for (const periode in groupedData) {
                for (const quality_id in groupedData[periode]) {
                    const qualityObj = {
                        periode: parseInt(periode),
                        quality: quality_id.trim()
                    };

                    // Initialize all machines with value 0
                    machines.forEach(machine => {
                        qualityObj[machine] = 0;
                    });

                    const machineData = groupedData[periode][quality_id];
                    for (const machine in machineData) {
                        qualityObj[machine] = machineData[machine];
                    }
                    transposedData.push(qualityObj);
                }
            }
            const totals = this.calculateTotal(transposedData);

            // Add totals row to transposed data
            transposedData.push(totals);
            return transposedData;
        },
        calculateTotal(data) {
            const totals = {
                periode: '',
                quality: 'Total',
                GLV1: 0,
                GLV2: 0,
                SLT1: 0,
                SLT2: 0,
                DELV: 0,
                GLV: 0,
                SLT: 0
            };

            data.forEach(entry => {
                for (const key in totals) {
                    if (key !== 'periode' && key !== 'quality') {
                        // Ensure value is converted to string before attempting replace
                        const value = String(entry[key] || 0);
                        totals[key] += parseFloat(value.replace(/,/g, '') || 0);
                    }
                    console.log(totals)
                }
            });

            for (const key in totals) {
                if (key !== 'periode' && key !== 'quality') {
                    totals[key] = this.$store.getters.convertToCurrencyUs(totals[key]);
                }
            }

            return totals;
        }
    },
}
</script>
<style scoped>
    .tr_datatable{
    background-color: #F5F7F8 !important;
    }
</style>