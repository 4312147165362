import {backendApi} from "../../backend-api-sr"

export const viewQueryProduction = (() =>{
    const fetchIndex = ( async (subPath, reqBody) => {
        var url = '/api/v3/sr/production/query-production-delivery'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    return {fetchIndex};
})()